<template>
<main class="py-24">
  <div class="max-w-xl mx-auto px-4">
    <div class="bg-white p-6 shadow-md rounded-lg">
      <h2 class="text-xl font-semibold mb-4 text-gray-800">Search Wristband</h2>
      <div class="flex flex-col sm:flex-row gap-3 items-stretch sm:items-center">
        <input
          type="text"
          v-model="filterText"
          placeholder="Enter wristband code..."
          class="flex-1 py-2 px-4 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          @click.prevent="searchRegistrations"
          class="bg-blue-500 hover:bg-blue-600 text-white rounded-lg p-2 flex items-center justify-center"
        >
          <MagnifyingGlassIcon class="h-6 w-6" />
        </button>
        <button
          @click.prevent="clearFilter"
          class="bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-lg p-2 flex items-center justify-center"
        >
          <XMarkIcon class="h-6 w-6" />
        </button>
      </div>
    </div>
  </div>
</main>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
  components: {
    MagnifyingGlassIcon,
    XMarkIcon
  },
  setup () {
    const router = useRouter()
    const filterText = ref('')

    const searchRegistrations = async () => {
      router.push('/registration-ticket/wristband/' + filterText.value)
    }

    const clearFilter = async () => {
      filterText.value = ''
    }

    return {
      searchRegistrations,
      filterText,
      clearFilter
    }
  }
})
</script>
