<template>
<main class="bg-gray-100 pb-10">
  <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
    <p v-show="!formState.loadError && !formState.registration" class="text-gray-600 text-center">
      Loading registration details, please wait...
    </p>

    <ErrorMessage :formError="formState.loadError" class="mt-6" />

    <!-- Back to List -->
    <div v-if="eventIdList > 0" class="mt-10">
      <a :href="'/registration-ticket/list/' + eventIdList" class="text-blue-600 hover:underline">&larr; Back to List</a>
    </div>

    <!-- Checkout Info -->
    <div v-if="formState.registration?.checkout" class="mt-10">
      <h3 class="text-lg font-semibold text-gray-800 mb-4">
        Checkout Info [#{{ formState.registration.checkout[0].checkoutId }}]
      </h3>

      <div class="bg-white border border-gray-200 rounded-lg shadow p-6 space-y-4">
        <p class="text-sm font-bold">{{ formState.registration.checkout[0].firstName }} {{ formState.registration.checkout[0].lastName }}</p>
        <p class="text-sm text-gray-700">{{ formState.registration.checkout[0].email }}</p>

        <div>
          <p class="text-sm font-bold">Ticket ID</p>
          <p class="text-sm text-gray-700">{{ formState.registration.checkout[0].guid }}</p>
        </div>

        <div v-if="formState.registration.checkout[0].confirmedAt">
          <p class="text-sm font-bold">Confirmed At</p>
          <p class="text-sm text-gray-700">{{ $filters.dateLon(formState.registration.checkout[0].confirmedAt) }}</p>
        </div>

        <div v-if="formState.registration.checkout[0].cancelledAt">
          <p class="text-sm font-bold text-red-500">Cancelled At</p>
          <p class="text-sm text-gray-700">{{ $filters.dateLon(formState.registration.checkout[0].cancelledAt) }}</p>
        </div>

        <div v-if="formState.registration.checkout[0].isPartner">
          <p class="text-sm font-bold text-green-600">SPECIFIED AS PARTNER</p>
        </div>

        <div v-if="formState.registration.checkout[0].country?.toLowerCase() !== 'gb'">
          <p class="text-sm font-bold text-blue-600">** {{ formState.registration.checkout[0].country }} - INTERNATIONAL **</p>
        </div>
      </div>
    </div>

    <!-- Delegates -->
    <div v-if="formState.registration?.delegates" class="mt-10">
      <h3 class="text-lg font-semibold text-gray-800 mb-4">Delegates</h3>

      <div class="bg-white border border-gray-200 rounded-lg shadow p-6 space-y-4">
        <div
          v-for="item in formState.registration.delegates"
          :key="item.id"
          @click.prevent="changeRegistered(item)"
          class="cursor-pointer hover:bg-gray-50 p-3 rounded-md transition"
        >
          <div>
            <span :class="[item.cancelled ? 'line-through text-gray-500' : '', 'font-bold']">
              {{ item.firstName }} {{ item.lastName }} [#{{ item.id }}]
            </span>
            <span class="inline-flex items-center ml-4 text-sm text-gray-700">
              <CheckCircleIcon v-if="item.registered" class="h-5 w-5 text-green-500 mr-2" />
              <XMarkIcon v-else class="h-5 w-5 text-red-500 mr-2" />
              {{ item.registered ? 'Registered' : 'Not Yet Registered' }}
            </span>
          </div>
          <p v-if="item.wristband" class="text-sm text-orange-500 font-medium mt-1">Band: {{ item.wristband }}</p>
        </div>
      </div>
    </div>

    <!-- Attendance Options -->
    <div v-if="formState.registration?.attendanceOptions" class="mt-10">
      <h3 class="text-lg font-semibold text-gray-800 mb-4">Session Attendance</h3>

      <div class="bg-white border border-gray-200 rounded-lg shadow p-6 space-y-4">
        <div
          v-for="option in formState.registration.attendanceOptions"
          :key="option.id"
          class="border-b border-gray-100 pb-4"
        >
          <div class="flex items-start text-sm text-gray-700">
            <component
              :is="option.attending == null ? 'QuestionMarkCircleIcon' : (option.attending ? 'CheckIcon' : 'XMarkIcon')"
              :class="option.attending == null ? 'text-red-500' : option.attending ? 'text-green-500' : 'text-red-500'"
              class="h-5 w-5 mr-2 flex-shrink-0"
            />
            {{ option.title }} - {{ $filters.dateTimeMed(option.publishedAt) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</template>

<script>
import axios from 'axios'
import { defineComponent, onMounted, computed, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import responseHelper from '@/helpers/response.helper'
// import SuccessMessage from '@/components/SuccessMessage.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { CheckCircleIcon, XMarkIcon, CheckIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
  components: {
    // SuccessMessage,
    ErrorMessage,
    CheckCircleIcon,
    XMarkIcon,
    QuestionMarkCircleIcon,
    CheckIcon
  },
  setup () {
    const route = useRoute()
    const registrationGuid = ref(route.params.guid)

    const formState = reactive({
      registration: undefined,
      loadError: responseHelper.noError,
      formSubmitted: false,
      formError: responseHelper.noError,
      successMessage: ''
    })

    const changeRegistered = async (item) => {
      axios.post(
        'pub/registrations/register',
        {
          guid: registrationGuid.value,
          delegateId: item.id
        }
      ).then((response) => {
        if (response?.data?.success && response?.data?.result) {
          formState.registration = response.data.result
        } else {
          formState.loadError = responseHelper.unexpectedResult
        }
      }).catch((error) => {
        formState.loadError = responseHelper.getMessageV2(error)
      })
    }

    onMounted(() => {
      axios.post(
        'pub/registrations/verify',
        {
          guid: registrationGuid.value
        }
      ).then((response) => {
        if (response?.data?.success && response?.data?.result) {
          formState.registration = response.data.result
        } else {
          formState.loadError = responseHelper.unexpectedResult
        }
      }).catch((error) => {
        formState.loadError = responseHelper.getMessageV2(error)
      })
    })

    const eventIdList = computed(() => {
      var eventId = 0
      if (formState && formState.registration && formState.registration.delegates.length > 0) {
        for (var i = 0; i < formState.registration.delegates.length; i++) {
          eventId = formState.registration.delegates[i].eventId
        }
      }
      return eventId
    })

    return {
      formState,
      registrationGuid,
      changeRegistered,
      eventIdList
    }
  }
})
</script>
